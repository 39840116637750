import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <div className="HomePage-wrapper">
      <div className="App-container">
        <Link to="/DrinksPage" className="Menu-item">
          <img src="/drinks.jpg" alt="" />
          <p>Piće</p>
        </Link>
        <Link to="/MasagePage" className="Menu-item">
          <img src="/massage.jpg" alt="" />
          <p>Masaže</p>
        </Link>
      </div>
    </div>
  );
};

export default HomePage;
