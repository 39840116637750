export const DrinkCategories = [
  {
    name: "Piće",
    items: [
      {
        name: "Negazirana voda",
        price: 1.5,
      },
      {
        name: "Kisjela voda",
        price: 1.5,
      },
      {
        name: "Negazirani sokovi",
        price: 2.5,
      },
      {
        name: "Gazirani sokovi",
        price: 2.5,
      },
      {
        name: "Red Bull",
        price: 4,
      },
    ],
  },
  {
    name: "Topli napici",
    items: [
      {
        name: "Espresso",
        price: 1.5,
      },
      {
        name: "Capuccino",
        price: 2,
      },
      {
        name: "Nes kafa",
        price: 2,
      },
      {
        name: "Topla čokolada",
        price: 2.5,
      },
      {
        name: "Čaj",
        price: 2,
      },
    ],
  },
  {
    name: "Prirodni sokovi",
    items: [
      {
        name: "Kruška i jabuka",
        price: 3,
      },
      {
        name: "Limunada",
        price: 2.5,
      },
      {
        name: "Američka limunada",
        price: 3,
      },
      {
        name: "Pomorandža",
        price: 3,
      },
      {
        name: "Prirodni mix",
        price: 3.5,
      },
    ],
  },
  {
    name: "Smoothies",
    items: [
      {
        name: "Crveni",
        price: 4,
      },
      {
        name: "Zeleni",
        price: 4,
      },
      {
        name: "Žuti",
        price: 4,
      },
      {
        name: "Ljubičasti",
        price: 4,
      },
    ],
  },
  {
    name: "Alkoholna pića",
    items: [
      {
        name: "Bijelo vino",
        price: 5.0,
      },
      {
        name: "Crno vino",
        price: 5.0,
      },
      {
        name: "Prosecco",
        price: 7,
      },
      {
        name: "Somersby",
        price: 3,
      },
      {
        name: "Heineken",
        price: 4,
      },
    ],
  },
];
